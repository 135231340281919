<template>
  <div class="tree-box">
    <normal-tree
      @check="checkChange"
      @onNodeExpand="onNodeExpand"
      @onNodeCollapse="onNodeCollapse"
      ref="treeRef"
      :data="treeData"
      nodeKey="id"
      :props="treeProps"
      :showCheckbox="showCheckbox"
      checkStrictly
      :defaultExpandAll="defaultExpandAll"
      :disabled="disabled"
    />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed, watch } from "vue";

const emits = defineEmits([
  "change",
  "onNodeExpand",
  "onNodeCollapse",
  "checkAll",
]);

const props = defineProps({
  // 使用类型 web||applet
  mode: {
    type: String,
    default: "web",
  },
  showCheckbox: {
    type: Boolean,
    default: true,
  },
  defaultExpandAll: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Array,
    default: () => [],
  },
  // 角色Id
  roleId: {
    type: [String, Number, Array],
    default: "",
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
  fetchName: {
    type: String,
    default: "",
  },
});

const { proxy } = getCurrentInstance();

// 角色权限 配置
const config = computed(() => {
  const { mode } = props;

  // 场景
  const scopes = {
    web: 0,
    applet: 1,
  };

  return {
    scope: scopes[mode],
  };
});

/* tree 树形结构操作 */
// ref
const treeRef = ref();
const treeData = ref(props.props);
watch(
  () => props.data,
  (nVal) => {
    treeData.value = nVal;
  },
  {
    deep: true,
  }
);
// 树形参数结构
const treeProps = {
  label: "name",
  children: "children",
};

// 监听
const checkChange = () => {
  const keys = treeRef.value.getCheckedKeys();

  // 判断是否全选
  const checkAll = keys.length === allRoleIds.value.length;
  emits("checkAll", { keys, checkAll });
  emits("change", keys);
};

// 设置tree key选中
const setCheckedKeys = (ids) => {
  // 赋值选中
  treeRef.value.setCheckedKeys(ids);
};
// 获取
const getCheckedKeys = () => {
  return treeRef.value.getCheckedKeys();
};

// 获取 node
const getNode = (res) => {
  return treeRef.value.getNode(res);
};

/* 查询操作 */
// 查询权限数据
const getAuthData = async () => {
  try {
    const { roleId } = props;
    let roleIds = typeof roleId == "object" ? roleId.join(",") : roleId;
    const res = await proxy.$storeDispatch(props.fetchName, {
      sceneType: config.value.scope,
      roleIds,
    });
    const result = (res.result || []).filter((d) => {
      // // 拦截 权限CODE
      // const codeTypes = [
      //   "gateEntryManage",
      //   "entryBusinessEntitiesManage",
      //   "gapTurnoverRateManage",
      //   "categoryProportionManage",
      // ];

      // // 拦截赋值
      // d.children = d.children.filter((item) => !codeTypes.includes(item.code));

      return true;
    });

    treeData.value = result;

    treeRef.value.hideLoading();
  } catch (error) {
    treeRef.value.hideLoading();
    return [];
  }
};

// 获取数据全key
const getCallAllRoleIds = (list = [], result = []) => {
  for (var i = 0; i < list.length; i++) {
    const item = list[i];
    const { id, children } = item;

    result.push(id);
    const keys = getCallAllRoleIds(children);
    result.push(...keys);
  }

  return result;
};
// 全角色id组
const allRoleIds = computed(() => getCallAllRoleIds(treeData.value));
// 设置全选
const setCheckKeysAll = () => {
  setCheckedKeys(allRoleIds.value);
};

/* 监听节点伸缩 */
// 展开
const onNodeExpand = (res) => {
  emits("onNodeExpand", res);
};
// 收起
const onNodeCollapse = (res) => {
  emits("onNodeCollapse", res);
};

// 初始
const init = async () => {
  await getAuthData();
};

defineExpose({
  init,
  setCheckedKeys,
  getCheckedKeys,
  getNode,
  setCheckKeysAll,
  checkChange,
});
</script>

<style lang="scss" scoped>
.tree-box {
  height: 100%;
}
</style>
