<template>
  <panel-action ref="panelActionRef" :panelTitle="customLayOutTitle">
    <!-- 表单 -->
    <div class="form-box ml-scrollbar">
      <ml-form
        :model="formData"
        :rules="formRules"
        labelWidth="100px"
        ref="formRef"
        @confirm="formSubmit"
        :showConfirm="!isActionExamine"
        labelPosition="top"
        :showBtn="!isActionExamine"
        bold
      >
        <div class="form-warpper">
          <ml-collapse-panel
            collapseKey="0"
            :showUpBorder="false"
            :isUseExpand="false"
          >
            <el-form-item label="角色名称：" prop="name">
              <ml-input
                placeholder="请输入角色名称"
                v-model="formData.name"
                :disabled="isActionExamine"
              ></ml-input>
            </el-form-item>

            <el-form-item label="状态：">
              <ml-switch
                v-model="formData.status"
                :disabled="isActionExamine"
              ></ml-switch>
            </el-form-item>

            <!-- 权限区域 -->
            <el-form-item
              label="角色权限："
              prop="menuIds"
              label-position="top"
            >
              <div class="role-auths-box ml-scrollbar">
                <div class="web-auth">
                  <div class="tree-box">
                    <div class="check-all-item" v-if="showAllRoleCheckbox">
                      <el-checkbox
                        v-model="webCheckAllValue"
                        @change="onTreeCheckAll($event)"
                        >全选</el-checkbox
                      >
                    </div>
                    <tree-role-auth
                      mode="web"
                      fetchName="fetchGetMenuList"
                      ref="treeRoleWebRef"
                      @change="checkTreeChange"
                      @checkAll="onTreeCheckAllCall($event)"
                      :disabled="isActionExamine"
                    ></tree-role-auth>
                  </div>
                </div>
              </div>
            </el-form-item>
          </ml-collapse-panel>
        </div>

        <!-- <template #btn-append>
          <p
            class="reset-auth"
            v-if="showResetCurrentDefault"
            @click="resetCurrentDefault"
          >
            重置为系统默认权限
          </p>
        </template> -->
      </ml-form>
    </div>
  </panel-action>
</template>

<script setup>
import treeRoleAuth from "@/components/tree/role-auth/index.vue";
import { onMounted, reactive, computed, ref, nextTick, onUnmounted } from "vue";
import { usePageModule } from "@/utils/mixin";
import Login from "../login.vue";
// import {
//   getWebDefaultCheckKeys,
//   getAppletDefaultCheckKeys,
// } from "./defaultKeys";

const { proxy, routeParams, mainStore } = usePageModule();

// 角色ID
const roleId = computed(() => routeParams.id);
// 页面操作类型 add||edit||examine
const actionType = computed(() => routeParams.actionType);
// 页面编辑
const isActionEdit = computed(() => actionType.value == "edit");
// 页面查看
const isActionExamine = computed(() => actionType.value == "examine");

// 是否显示全选角色权限
const showAllRoleCheckbox = computed(() => !isActionExamine.value);

// 顶部标题
const customLayOutTitle = computed(() => {
  const titleType = {
    add: "添加",
    edit: "修改",
  };
  const type = titleType[actionType.value];

  if (isActionExamine.value) {
    return "角色权限";
  } else {
    return `${type}角色权限`;
  }
});

/* 表单操作 */
// ref
const formRef = ref();
// 默认数据
const formDefaultData = () => ({
  id: "",
  name: "",
  status: 1,
  menuIds: [],
});
// 表单数据
const formData = reactive(formDefaultData());
// 表单验证
const formRules = {
  name: [
    {
      required: true,
      message: "请输入角色名称",
    },
  ],
  menuIds: [
    {
      required: true,
      message: "请选择角色权限",
    },
  ],
};
// 提交
const formSubmit = async (close) => {
  const testRes = await proxy.$testForm(close, formRef.value);

  if (!testRes) {
    return;
  }

  const isEdit = isActionEdit.value;
  const fetchName = isEdit ? "fetchModifyRole" : "fetchAddRole";
  const disptchTips = isEdit ? "修改成功" : "添加成功";

  let result = formData;
  if (isActionEdit.value) {
    // console.log(roleDetail);
    // console.log(formData);
    result = proxy.$getDataUpdateAfter(roleDetail, formData);
  }

  const resultLength = Object.keys(result).length;
  if (!resultLength) {
    proxy.$successMsg(disptchTips);
    proxy.$delayBack();
    return;
  } else {
    result.id = roleId.value;
  }

  const data = [];
  if (result.menuIds) {
    for (let i = 0; i < result.menuIds.length; i++) {
      const val = result.menuIds[i];
      data.push(val);
    }
  }

  // console.log(result);
  const ids = data.map((item) => `menuIds=${item}`).join("&");
  // console.log(ids);
  // console.log(result);
  delete result["menuIds"];
  try {
    await proxy.$storeDispatch(fetchName, {
      params: result,
      query: ids,
    });
    proxy.$delayBack(() => {
      proxy.$successMsg(disptchTips);
    });
    close();
  } catch (error) {
    close();
  }
};

/* tree 树形结构操作 */
// ref
const treeRoleWebRef = ref();
const webCheckAllValue = ref();
// 监听
const checkTreeChange = () => {
  const web_keys = treeRoleWebRef.value.getCheckedKeys();
  formData.menuIds = [...web_keys];

  setTimeout(() => {
    formRef.value.clearValidate();
  });
};
// 监听checkbox全选操作
const onTreeCheckAll = (check, type) => {
  if (check) {
    treeRoleWebRef.value.setCheckKeysAll();
  } else {
    treeRoleWebRef.value.setCheckedKeys([]);
  }

  setTimeout(() => {
    checkTreeChange();
  });
};
// 监听tree全选回调
const onTreeCheckAllCall = ({ checkAll }, type) => {
  webCheckAllValue.value = checkAll;
};

/* 查询操作 */
// 数据
const roleDetail = reactive(formDefaultData());
// 查询角色详情
const getRoleDetail = async () => {
  try {
    const res = await proxy.$storeDispatch("fetchGetRoleDetails", roleId.value);
    const result = res.result;
    proxy.$updateParams(formData, result);
    proxy.$updateParams(roleDetail, result);

    // 赋值选中
    treeRoleWebRef.value.setCheckedKeys(result.menuIds);

    setTimeout(() => {
      treeRoleWebRef.value.checkChange();
    });
  } catch (error) {}
};

// // 查询角色的权限数据
// const getRoleDetailsMenuList = async (sceneType) => {
//   try {
//     const params = {
//       sceneType,
//       roleId: roleId.value,
//     };

//     const res = await proxy.$storeDispatch("fetchGetRoleMenuList", params);
//     const result = res.result;
//     // console.log(result);
//     return result;
//   } catch (error) {}
// };
// 获取数据的id组
const getItemIds = (children = [], result = []) => {
  if (!children.length) {
    return result;
  }

  const ids = children.map((d) => d.id);
  result.push(...ids);

  for (let i = 0; i < children.length; i++) {
    const d = children[i];
    getItemIds(d.children, result);
  }

  return result;
};

// 重置默认
// const resetCurrentDefault = () => {
//   // 特殊 角色
//   if (isRoleSpecial.value) {
//     // web端 默认key
//     const webDefaultResult = getWebDefaultCheckKeys(treeRoleWebRef);

//     // 小程序端 默认key
//     const appletDefaultResult = getAppletDefaultCheckKeys(treeRoleAppletRef);

//     // 使用赋值的参数名
//     let usePushParamName = "";

//     // 店长
//     if (isStoreRole.value) {
//       usePushParamName = "storeDefaultCheckedKeys";
//     }

//     // 经理
//     if (isManageRole.value) {
//       usePushParamName = "manageDefaultCheckedKeys";
//     }

//     // 会计
//     if (isAccountingRole.value) {
//       usePushParamName = "accountingDefaultCheckedKeys";
//     }

//     const webKeys = webDefaultResult[usePushParamName].value;
//     const appletKeys = appletDefaultResult[usePushParamName].value;

//     // 赋值选中
//     treeRoleWebRef.value.setCheckedKeys(webKeys);
//     // 赋值选中
//     treeRoleAppletRef.value.setCheckedKeys(appletKeys);

//     // 赋值 菜单ID
//     formData.menuIds = [...webKeys, ...appletKeys];
//     // console.log(usePushParamName);
//     // console.log(appletDefaultResult);
//     // console.log(appletDefaultResult[usePushParamName].value);
//   }
// };

// 初始
const init = () => {
  getRoleDetail();
};

onMounted(async () => {
  // 赋值标题
  mainStore.customLayOutTitle = customLayOutTitle.value;

  await treeRoleWebRef.value.init();
  // 修改
  if (roleId.value) {
    init();
  }
});

onUnmounted(() => {
  mainStore.customLayOutTitle = "";
});
</script>

<style lang="scss" scoped>
.form-box {
  width: 60%;
  margin: auto;
  overflow-y: auto;
  ::v-deep(.el-form) {
    display: grid;
    grid-template-rows: 1fr auto;

    .role-auths-box {
      width: 100%;
      height: 428px;
      overflow-y: auto;
      .web-auth,
      .applet-auth {
        .title {
          font-weight: bold;
          padding-bottom: 15px;
        }
      }
    }

    .reset-auth {
      color: var(--el-color-primary);
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
